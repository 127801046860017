import React from 'react';
import { MdMenu, MdClose } from 'react-icons/md';
import { AppBar, Box, Container, Drawer, IconButton, Toolbar } from '@mui/material';
import { APP_URL } from '../../../consts/url.const';
import { HeaderLinks } from '../../atoms/header-tabs/header-tabs.atom';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { APP_SETTINGS } from '../../../consts/app.const';
import { LoginLogoutButton } from '../../atoms/login-logout-button/login-logout-button';
import { HeaderTabMenu } from '../../atoms/header-tabs/header-tabs-menu.atom';

export const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const handleClick = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <AppBar position="fixed" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 101 }}>
        <Container maxWidth="xl">
          <Toolbar sx={{ borderBottom: '1px solid', borderColor: 'secondary.light' }} disableGutters>
            <Box>
              <Link to={APP_URL.HOME.url} style={{ lineHeight: 0 }}>
                <StaticImage src="../../../images/adatree.webp" alt={'Adatree logo'} height={32} placeholder="none" />
              </Link>
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                flex: 'auto ',
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {!isDrawerOpen && (
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => {
                    setDrawerOpen(true);
                  }}
                  color="inherit"
                >
                  <MdMenu />
                </IconButton>
              )}
              {isDrawerOpen && (
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => {
                    setDrawerOpen(false);
                  }}
                  color="inherit"
                >
                  <MdClose />
                </IconButton>
              )}

              <Drawer
                anchor="top"
                open={isDrawerOpen}
                onClose={() => {
                  setDrawerOpen(false);
                }}
              >
                <Box
                  sx={{
                    mt: `${APP_SETTINGS.headerHeight}`,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <HeaderTabMenu onClick={handleClick} />
                </Box>
              </Drawer>
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                flex: 'auto ',
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <HeaderLinks />
              <LoginLogoutButton sx={{ ml: 3 }} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Empty div set to the AppBar height to push content down the page */}
      <Box sx={{ height: { xs: `${APP_SETTINGS.headerMobileHeight}`, md: `${APP_SETTINGS.headerHeight}` } }}></Box>
    </>
  );
};
