import * as React from 'react';
import PropTypes from 'prop-types';
import themeOverride from '../../src/theme/theme-override';
import {
  AnalyticsProvider,
  CopyBuilder,
  CopyProvider,
  ConsentFormProvider,
  DataRecipientsProvider,
  Helper,
  ThemeProvider,
  defaultTheme,
} from '@adatree/atomic-components';
import { Industry } from '@adatree/react-api-sdk';
import { DataRecipients } from '../../src/consts/app.const';
import { APP_SETTINGS } from '../../src/consts/app.const';

export default function TopLayout(props) {
  const adrDataRecipient = Helper.getAdrDataRecipients(DataRecipients);
  const primaryDataRecipient = Helper.getPrimaryDataRecipients(DataRecipients);
  return (
    <React.Fragment>
      <AnalyticsProvider onTrack={() => {}}>
        <ThemeProvider theme={defaultTheme} extendTheme={themeOverride}>
          <DataRecipientsProvider
            accreditationNum={APP_SETTINGS.companyAccreditationNumber}
            initialDataRecipients={DataRecipients}
          >
            <CopyProvider
              initialCopy={CopyBuilder.generateCopy(adrDataRecipient, primaryDataRecipient, Industry.Banking)}
            >
              <ConsentFormProvider>{props.children}</ConsentFormProvider>
            </CopyProvider>
          </DataRecipientsProvider>
        </ThemeProvider>
      </AnalyticsProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
