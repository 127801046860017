import { APP_URL } from '../url.const';
import { NavItem } from './nav.types';

export const ApiNav: NavItem = {
  label: APP_URL.API_DOCS.label,
  menuItems: [
    {
      label: APP_URL.API_DOC_BANKING.label,
      url: APP_URL.API_DOC_BANKING.url,
    },
    {
      label: APP_URL.API_DOC_CONSENT.label,
      url: APP_URL.API_DOC_CONSENT.url,
    },
    {
      label: APP_URL.API_DOC_DATA.label,
      url: APP_URL.API_DOC_DATA.url,
    },
    {
      label: APP_URL.API_DOC_INSIGHTS.label,
      url: APP_URL.API_DOC_INSIGHTS.url,
    },
  ],
};
