import { Logger } from '@adatree/atomic-components';
import { APP_URL } from '../../consts/url.const';

export const getAccessToken = (): string => {
  const accessToken = sessionStorage.getItem('ACCESS_TOKEN');
  return accessToken ? accessToken : '';
};

export const setAccessToken = (accessToken: string) => {
  sessionStorage.setItem('ACCESS_TOKEN', accessToken);
};

export const validateUriAndState = (state: string): boolean => {
  const STATE_KEY = 'client_state';
  const localState = sessionStorage.getItem(STATE_KEY);
  let currentUri = window.location.origin + window.location.pathname;
  let localRedirectUri;

  currentUri = currentUri.endsWith('/') ? currentUri.slice(0, -1) : currentUri;

  if (localState) {
    localRedirectUri = sessionStorage.getItem(localState);
  }

  if (localState === state && localRedirectUri === currentUri) {
    sessionStorage.removeItem(STATE_KEY);
    sessionStorage.removeItem(localState);
    return true;
  } else {
    Logger.error(
      `CSRF check failed. Local state ${localState} and local URI ${localRedirectUri} do not match callback state ${state} and callback URI ${currentUri}`
    );
    return false;
  }
};

export const getRetrunToPath = (): string => {
  let path = APP_URL.HOME.url;

  if (window && window.location) {
    Logger.debug('getRetrunToPath window.location.pathname', window.location.pathname);
    path = window.location.pathname;
  }

  if (path.includes(APP_URL.AUTH_LOGOUT.url) || path.includes(APP_URL.AUTH_CALLBACK.url)) {
    Logger.debug('getRetrunToPath path.includes', true);
    return APP_URL.HOME.url;
  }

  Logger.debug('getRetrunToPath path', path);
  return path;
};

export const clearAuthSessionStorage = (): void => {
  sessionStorage.removeItem('ACCESS_TOKEN');
};
