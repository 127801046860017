import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { NAV_ITEMS } from '../../../consts/nav/nav.const';
import { Link } from 'gatsby';
import { BsChevronDown } from 'react-icons/bs';
import { UrlItem } from '../../../consts/url.const';
import { LoginLogoutButton } from '../login-logout-button/login-logout-button';

interface Props {
  onClick: () => void;
}

export const HeaderTabMenu = ({ onClick }: Props) => {
  const renderAccordion = (label: string, menuItems: UrlItem[]) => {
    return (
      <>
        <Accordion disableGutters>
          <AccordionSummary sx={{ minHeight: '6rem' }} expandIcon={<BsChevronDown />}>
            <Typography>{label}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ py: 1, backgroundColor: '#f5f5f5' }}>
            {menuItems.map((item) => {
              return (
                <Box key={item.url} sx={{ py: 1.5 }}>
                  <Link key={item.url} to={item.url} onClick={onClick}>
                    {item.label}
                  </Link>
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <>
      {renderAccordion(NAV_ITEMS.consentApp.label, NAV_ITEMS.consentApp.menuItems)}
      {renderAccordion(NAV_ITEMS.developer.label, NAV_ITEMS.developer.menuItems)}
      <LoginLogoutButton sx={{ p: 3, px: 2, display: 'flex', justifyContent: 'end' }} />
    </>
  );
};
