import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { navigate } from 'gatsby';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { APP_SETTINGS } from './src/consts/app.const';
import { Bootstrap } from './src/components/organisms/bootstrap/bootstrap.organism';
import { Header } from './src/components/organisms/navigation/header.organism';
import { APP_URL } from './src/consts/url.const';
import { Logger } from '@adatree/atomic-components';
import './src/css/global.css';

const onRedirectCallback = (appState: AppState | undefined) => {
  Logger.debug('onRedirectCallback - appState', appState);
  navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <Auth0Provider
      audience={'https://' + APP_SETTINGS.backendDomain}
      cacheLocation="localstorage"
      clientId={APP_SETTINGS.authProviderClientId}
      domain={APP_SETTINGS.authProviderDomain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={`${window.location.origin}${APP_URL.AUTH_CALLBACK.url}`}
      scope="consumer:consents:write consumer:consents:read consumer:scopes:read consumer:use-cases:read consumer:data-holders:read consumer:authorizations:write consumer:tokens:write adr:common:userinfo:read adr:banking:read data:banking:accounts:read data:banking:payees:read data:banking:transactions:read data:banking:regular_payments:read data:energy:plans:read data:energy:accounts:read data:energy:invoices:read data:energy:billing:read data:energy:electricity.servicepoints:read data:energy:electricity.usage:read data:common:customers:read insights:banking:account insights:energy:account"
    >
      <Bootstrap />
      {element}
    </Auth0Provider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <>
      <Header />
      {element}
    </>
  );
};
