import { APP_URL } from '../url.const';
import { NavItem } from './nav.types';

export const DashboardApp: NavItem = {
  label: 'Dashboard App',
  menuItems: [
    {
      label: APP_URL.THEME_EDITOR.label,
      url: APP_URL.THEME_EDITOR.url,
    },
  ],
};
