import React from 'react';
import { Box } from '@mui/material';
import { NAV_ITEMS } from '../../../consts/nav/nav.const';
import { NavDropdownItem } from '../nav-item/nav-dropdown-item.atom';

export const HeaderLinks = () => {
  return (
    <Box sx={{ py: 2, pl: 4, display: { xs: 'none', md: 'flex' } }} alignItems="center">
      <NavDropdownItem navItem={NAV_ITEMS.consentApp}></NavDropdownItem>
      <NavDropdownItem navItem={NAV_ITEMS.developer}></NavDropdownItem>
    </Box>
  );
};
