import React, { useEffect } from 'react';
import { Logger } from '@adatree/atomic-components';
import { useAuth0 } from '@auth0/auth0-react';
import { setAccessToken } from '../../../utils/auth/auth.util';
import { APP_FLAGS_PREFIX } from '../../../consts/app.flags';

const loadFlags = () => {
  if (window && window.location && localStorage) {
    const searchParams = new URLSearchParams(window.location.search);

    for (const key of searchParams.keys()) {
      if (key.startsWith(APP_FLAGS_PREFIX)) {
        const value = searchParams.get(key);
        Logger.debug(`Storing flag ${key} with value ${value} into local storage`);
        localStorage.setItem(key, value ? value : 'false');
      }
    }
  }
};

export const Bootstrap = () => {
  // const location = useLocation();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  // Set the access token
  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAccessToken(accessToken);
        }
      } catch (e) {
        // An error is thrown if the user is not logged in, this is expected, no need
        // to log it. Only log if an error is thrown and the user isAuthenticated
        if (isAuthenticated) {
          Logger.error('Error getting access token', e);
        }
      }
    };

    getAccessToken();
    loadFlags();
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  return <></>;
};
