import { ApiNav } from './api-nav.const';
import { ChangelogNav } from './changelog-nav.const';
import { ConsentAppNav } from './consent-app-nav.const';
import { DashboardApp } from './dashboard-app-nav.const';
import { DeveloperNav } from './developer-nav.const';
import { DocsContentDashboardNav } from './docs-content-dashboard-nav.const';
import { GeneralNav } from './general-nav.const';
import { NavItems } from './nav.types';

export const NAV_ITEMS: NavItems = {
  general: GeneralNav,
  api: ApiNav,
  changelog: ChangelogNav,
  consentApp: ConsentAppNav,
  developer: DeveloperNav,
  dashboardApp: DashboardApp,
  docsConsentDashboard: DocsContentDashboardNav,
};
