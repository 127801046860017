import { APP_URL } from '../url.const';
import { NavItem } from './nav.types';

export const DeveloperNav: NavItem = {
  label: 'Developer',
  menuItems: [
    {
      label: APP_URL.API_DOCS.label,
      url: APP_URL.API_DOCS.url,
    },
    {
      label: APP_URL.CHANGELOG.label,
      url: APP_URL.CHANGELOG.url,
    },
    {
      label: APP_URL.DOCS.label,
      url: APP_URL.DOCS.url,
    },
    {
      label: APP_URL.THEME_EDITOR.label,
      url: APP_URL.THEME_EDITOR.url,
    },
  ],
};
