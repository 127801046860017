export type UrlItem = {
  label: string;
  url: string;
};

/**
 * Trailing slash required on URLs to preserve passing of URL query parameters
 */

const adatreeUrls = {
  ADT_BLOG: { label: 'Blog', url: 'https://adatree.com.au/?page_id=9' },
  ADT_CAREERS: { label: 'Careers', url: 'https://www.adatree.com.au/careers?hsLang=en' },
  ADT_CONTACT_US: { label: 'Contact us', url: 'https://www.adatree.com.au/contact?hsLang=en' },
  ADT_HOME: { label: 'Home', url: 'https://www.adatree.com.au/' },
  ADT_TEAM: { label: 'About', url: 'https://www.adatree.com.au/about?hsLang=en' },
};

const apiDocsUrls = {
  API_DOCS: { label: 'API Docs', url: '/docs/api/' },
  API_DOC_BANKING: { label: 'Banking API', url: '/docs/api/banking/' },
  API_DOC_CONSENT: { label: 'Consent API', url: '/docs/api/consent/' },
  API_DOC_DATA: { label: 'Data API', url: '/docs/api/data/' },
  API_DOC_INSIGHTS: { label: 'Insights API', url: '/docs/api/insights/' },
};

const authUrls = {
  AUTH_CALLBACK: { label: 'Authentication callback', url: '/authentication/' },
  AUTH_LOGOUT: { label: 'Logout', url: '/logout/' },
};

const changelogUrls = {
  CHANGELOG: { label: 'API Changelogs', url: '/docs/changelog' },
  CHANGELOG_BANKING: { label: 'Banking Changelog', url: '/docs/changelog/banking' },
  CHANGELOG_CONSENT: { label: 'Consent Changelog', url: '/docs/changelog/consent' },
  CHANGELOG_DATA: { label: 'Data Changelog', url: '/docs/changelog/data' },
};

const consentUrls = {
  CONSENT: { label: 'View my data', url: '/consent/' },
  CONSENT_INTRO: { label: 'Get started', url: '/access-your-cdr-data/' },
  CONSENT_CALLBACK: { label: 'Consent callback', url: '/redirect/' },
  CONSENT_CREATE: { label: 'Connect my data', url: '/consent/create/' },
  CONSENT_DETAIL: { label: 'View my consent', url: '/consent/details/#consentId=' },
  CONSENT_EDIT: { label: 'Edit my consent', url: '/consent/edit/#consentId=' },
  CONSENT_LIST: { label: 'Manage my consents', url: '/consent/list/' },
};

const docsUrls = {
  DOCS: { label: 'Documentation', url: '/docs/' },
};

const consentDocUrls = {
  DOCS_CONSENT_DASHBOARD_HOME: { label: 'Consent Dashboard', url: '/docs/consent-dashboard/' },
  DOCS_CONSENT_DASHBOARD_USER_FLOWS: { label: 'User flows', url: '/docs/consent-dashboard/user-flows/' },
  DOCS_CONSENT_DASHBOARD_REDIRECTS: {
    label: 'Callback redirects',
    url: '/docs/consent-dashboard/consent-callback-redirects/',
  },
  DOCS_CONSENT_DASHBOARD_URL_PARMAS: {
    label: 'URL Parameters',
    url: '/docs/consent-dashboard/dashboard-url-parameters',
  },
};

const generalUrls = {
  PLACEHOLDER: { label: 'Placeholder', url: '/placeholder/' },
  QUICKSTART: { label: 'Quickstart', url: '' },
  HOME: { label: 'Home', url: '/' },
  THEME_EDITOR: { label: 'Theme Editor', url: '/theme-editor/' },
};

export const APP_URL = {
  ...adatreeUrls,
  ...apiDocsUrls,
  ...authUrls,
  ...changelogUrls,
  ...consentUrls,
  ...consentDocUrls,
  ...docsUrls,
  ...generalUrls,
};
