import { APP_URL } from '../url.const';
import { NavItem } from './nav.types';

export const GeneralNav: NavItem = {
  label: 'Adatree',
  menuItems: [
    {
      label: APP_URL.ADT_HOME.label,
      url: APP_URL.ADT_HOME.url,
    },
    {
      label: APP_URL.ADT_CONTACT_US.label,
      url: APP_URL.ADT_CONTACT_US.url,
    },
  ],
};
