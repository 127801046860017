import { APP_URL } from '../url.const';
import { NavItem } from './nav.types';

export const ConsentAppNav: NavItem = {
  label: 'Access your CDR data',
  menuItems: [
    {
      label: APP_URL.CONSENT_INTRO.label,
      url: APP_URL.CONSENT_INTRO.url,
    },
    {
      label: APP_URL.CONSENT.label,
      url: APP_URL.CONSENT.url,
    },
    {
      label: APP_URL.CONSENT_CREATE.label,
      url: APP_URL.CONSENT_CREATE.url,
    },
  ],
};
