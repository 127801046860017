// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from '@reach/router';
import { LinkProps } from '@mui/material/Link';

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (reach/router)
    return <RouterLink ref={ref} to={href} {...other} />;
  }
);

LinkBehavior.displayName = 'LinkBehavior';

const themeOverride = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
};

export default themeOverride;
