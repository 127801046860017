exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-access-your-cdr-data-index-tsx": () => import("./../../../src/pages/access-your-cdr-data/index.tsx" /* webpackChunkName: "component---src-pages-access-your-cdr-data-index-tsx" */),
  "component---src-pages-authentication-tsx": () => import("./../../../src/pages/authentication.tsx" /* webpackChunkName: "component---src-pages-authentication-tsx" */),
  "component---src-pages-consent-create-tsx": () => import("./../../../src/pages/consent/create.tsx" /* webpackChunkName: "component---src-pages-consent-create-tsx" */),
  "component---src-pages-consent-details-tsx": () => import("./../../../src/pages/consent/details.tsx" /* webpackChunkName: "component---src-pages-consent-details-tsx" */),
  "component---src-pages-consent-edit-tsx": () => import("./../../../src/pages/consent/edit.tsx" /* webpackChunkName: "component---src-pages-consent-edit-tsx" */),
  "component---src-pages-consent-tsx": () => import("./../../../src/pages/consent.tsx" /* webpackChunkName: "component---src-pages-consent-tsx" */),
  "component---src-pages-docs-api-banking-tsx": () => import("./../../../src/pages/docs/api/banking.tsx" /* webpackChunkName: "component---src-pages-docs-api-banking-tsx" */),
  "component---src-pages-docs-api-consent-tsx": () => import("./../../../src/pages/docs/api/consent.tsx" /* webpackChunkName: "component---src-pages-docs-api-consent-tsx" */),
  "component---src-pages-docs-api-data-tsx": () => import("./../../../src/pages/docs/api/data.tsx" /* webpackChunkName: "component---src-pages-docs-api-data-tsx" */),
  "component---src-pages-docs-api-insights-tsx": () => import("./../../../src/pages/docs/api/insights.tsx" /* webpackChunkName: "component---src-pages-docs-api-insights-tsx" */),
  "component---src-pages-docs-api-tsx": () => import("./../../../src/pages/docs/api.tsx" /* webpackChunkName: "component---src-pages-docs-api-tsx" */),
  "component---src-pages-docs-changelog-banking-index-mdx": () => import("./../../../src/pages/docs/changelog/banking/index.mdx" /* webpackChunkName: "component---src-pages-docs-changelog-banking-index-mdx" */),
  "component---src-pages-docs-changelog-consent-index-mdx": () => import("./../../../src/pages/docs/changelog/consent/index.mdx" /* webpackChunkName: "component---src-pages-docs-changelog-consent-index-mdx" */),
  "component---src-pages-docs-changelog-data-index-mdx": () => import("./../../../src/pages/docs/changelog/data/index.mdx" /* webpackChunkName: "component---src-pages-docs-changelog-data-index-mdx" */),
  "component---src-pages-docs-changelog-index-tsx": () => import("./../../../src/pages/docs/changelog/index.tsx" /* webpackChunkName: "component---src-pages-docs-changelog-index-tsx" */),
  "component---src-pages-docs-consent-dashboard-consent-callback-redirects-index-mdx": () => import("./../../../src/pages/docs/consent-dashboard/consent-callback-redirects/index.mdx" /* webpackChunkName: "component---src-pages-docs-consent-dashboard-consent-callback-redirects-index-mdx" */),
  "component---src-pages-docs-consent-dashboard-dashboard-url-parameters-index-mdx": () => import("./../../../src/pages/docs/consent-dashboard/dashboard-url-parameters/index.mdx" /* webpackChunkName: "component---src-pages-docs-consent-dashboard-dashboard-url-parameters-index-mdx" */),
  "component---src-pages-docs-consent-dashboard-index-mdx": () => import("./../../../src/pages/docs/consent-dashboard/index.mdx" /* webpackChunkName: "component---src-pages-docs-consent-dashboard-index-mdx" */),
  "component---src-pages-docs-consent-dashboard-user-flows-index-mdx": () => import("./../../../src/pages/docs/consent-dashboard/user-flows/index.mdx" /* webpackChunkName: "component---src-pages-docs-consent-dashboard-user-flows-index-mdx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-theme-editor-tsx": () => import("./../../../src/pages/theme-editor.tsx" /* webpackChunkName: "component---src-pages-theme-editor-tsx" */)
}

