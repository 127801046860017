import { APP_URL } from '../url.const';
import { NavItem } from './nav.types';

export const DocsContentDashboardNav: NavItem = {
  label: 'Docs',
  menuItems: [
    APP_URL.DOCS_CONSENT_DASHBOARD_HOME,
    APP_URL.DOCS_CONSENT_DASHBOARD_USER_FLOWS,
    APP_URL.DOCS_CONSENT_DASHBOARD_REDIRECTS,
    APP_URL.DOCS_CONSENT_DASHBOARD_URL_PARMAS,
  ],
};
