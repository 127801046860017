import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, SxProps } from '@mui/material';
import { MdLogin } from 'react-icons/md';
import { MdLogout } from 'react-icons/md';
import { clearAuthSessionStorage, getRetrunToPath } from '../../../utils/auth/auth.util';
import { APP_URL } from '../../../consts/url.const';

type LoginLogoutButtonProps = {
  sx?: SxProps;
};

export const LoginLogoutButton = (props: LoginLogoutButtonProps) => {
  const { sx } = props;
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  const handleLogout = () => {
    clearAuthSessionStorage();
    logout({ returnTo: `${window.location.origin}${APP_URL.AUTH_LOGOUT.url}` });
  };

  return (
    <Box sx={sx}>
      {isLoading && <Box sx={{ height: '4rem', width: '12rem' }}></Box>}
      {!isAuthenticated && !isLoading && (
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<MdLogin />}
          onClick={() => loginWithRedirect({ appState: { returnTo: getRetrunToPath() } })}
          sx={{ height: '4rem' }}
        >
          Sign in
        </Button>
      )}
      {isAuthenticated && !isLoading && (
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<MdLogout />}
          onClick={() => handleLogout()}
          sx={{ height: '4rem' }}
        >
          Sign out
        </Button>
      )}
    </Box>
  );
};
