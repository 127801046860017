import { DataRecipient, DataRecipientType } from '@adatree/atomic-components';

const apiSettings = {
  backendDomain: process.env.GATSBY_BACKEND_DOMAIN || '',
  backendProtocol: process.env.GATSBY_BACKEND_PROTOCOL || '',
  nonprodServerUrl: process.env.GATSBY_NONPROD_SERVER_URL || '',
};

const applicationSettings = {
  headerMobileHeight: '56px',
  headerHeight: '69px',
  productionOriginUrl: 'https://developer.adatree.com.au',
  simulateBackend: false,
  timestamp: process.env.GATSBY_TIMESTAMP || 'no-timestamp-set',
  version: process.env.GATSBY_VERSION || 'no-version-set',
};

const authSettings = {
  authProviderDomain: process.env.GATSBY_AUTH_PROVIDER_DOMAIN || '',
  authProviderClientId: process.env.GATSBY_AUTH_PROVIDER_CLIENT_ID || '',
};

const consentSettings = {
  cdrPolicyUrl: 'https://www.adatree.com.au/cdrpolicy',
  companyName: 'Adatree',
  companyAccreditationNumber: 'ADRBNK000071',
  companyLogo: 'https://adatree-prod.dashboard.adatree.com.au/images/adatree/adatree-logo.png',
  complaintEmail: 'consents@adatree.com.au',
  consentCallbackUri: process.env.GATSBY_CONSENT_CALLBACK_URI || '',
  consentUseCase: process.env.GATSBY_CONSENT_USE_CASE || '',
  dataPolicyUrl: 'https://www.adatree.com.au/cdrpolicy',
  dataSharingRevocationEmail: 'consents@adatree.com.au',
  defaultSoftwareProductId: process.env.GATSBY_DEFAULT_SOFTWARE_PRODUCT_ID || '',
  description: 'Adatree is a CDR Accredited Data Recipient who helps you share access to your data with your consent.',
  protectionFrameworkUrl: 'https://adatree.com.au/privacy',
  type: DataRecipientType.ACCREDITED_DATA_RECIPIENT,
  underCdrPrincipal: false,
};

export const APP_SETTINGS = {
  ...apiSettings,
  ...applicationSettings,
  ...authSettings,
  ...consentSettings,
};

export const DataRecipients: DataRecipient[] = [
  {
    cdrPolicyUrl: APP_SETTINGS.cdrPolicyUrl,
    complaintEmail: APP_SETTINGS.complaintEmail,
    dataSharingRevocationEmail: APP_SETTINGS.dataSharingRevocationEmail,
    description: APP_SETTINGS.description,
    logo: APP_SETTINGS.companyLogo,
    name: APP_SETTINGS.companyName,
    type: APP_SETTINGS.type,
  },
];
